/**
 * Import libraries -> use global typescript file
 */

/**
 * Import modules
 */
import CommonApplication from './../../../shared/js/application';

import Teasers from "./bricks/teasers";
import MobileNavigation from "../../msr/js/navigation/mobile-navigation";
import TourDetail from './../../../shared/js/tour-detail';

/**
 * Application class
 */
class Application extends CommonApplication {
    run() {
        super.run();

        Teasers.initialize();
        MobileNavigation.initialize();
        TourDetail.initialize();
    }

}

export default Application;