import Component from "../../../../shared/js/common/component";
import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

export default class Teasers extends Component {
    static readonly componentName: string = "b-teaser";
    sliderElement: HTMLElement;
    swiper: Swiper;
    sliderWrapper: HTMLElement;

    init(): void {
        this.sliderElement = this.element.querySelector('.swiper');
        this.registerEvents();
        window.onresize = () => {
            this.registerEvents();
        }
    }

    registerEvents() {
        if (this.sliderElement) {
            if (window.matchMedia("(max-width: 575px)").matches) {
                this.initSlider();
            } else {
                this.destroySlider();
            }
        }
    }

    initSlider() {
        Swiper.use([Navigation, Pagination]);

        this.swiper = new Swiper(this.sliderElement, {
            loop: true,
            pagination: {
                el: '.swiper-pagination',
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            autoHeight: true
        });
    }

    destroySlider() {
        this.swiper.destroy();
    }
}